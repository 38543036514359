import { Listbox, Transition } from '@headlessui/react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export type Option = {
  value: string;
  label?: string;
};

type SelectBoxProps = {
  options: Option[];
  defaultValue?: Option;
  variant?: 'outlined' | 'contained' | 'text';
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  direction?: 'up' | 'down';
};

const variantClasses = {
  contained: {
    primary: 'bg-primary text-white hover:opacity-90',
    secondary: 'bg-gray-500 text-white hover:bg-gray-600',
    error: 'bg-red-500 text-white hover:bg-red-600',
    warning: 'bg-yellow-500 text-white hover:bg-yellow-600',
    success: 'bg-green-500 text-white hover:bg-green-600',
    info: 'bg-blue-500 text-white hover:bg-blue-600',
    default: 'bg-gray-500 text-white hover:bg-gray-600',
  },
  outlined: {
    primary:
      'border border-primary text-primary hover:bg-primary hover:text-white',
    secondary:
      'border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white',
    error:
      'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white',
    warning:
      'border border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white',
    success:
      'border border-green-500 text-green-500 hover:bg-green-500 hover:text-white',
    info: 'border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white',
    default:
      'border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white',
  },
  text: {
    primary: 'text-primary hover:bg-primary hover:text-white',
    secondary: 'text-gray-500 hover:bg-gray-500 hover:text-white',
    error: 'text-red-500 hover:bg-red-500 hover:text-white',
    warning: 'text-yellow-500 hover:bg-yellow-500 hover:text-white',
    success: 'text-green-500 hover:bg-green-500 hover:text-white',
    info: 'text-blue-500 hover:bg-blue-500 hover:text-white',
    default: 'text-gray-500 hover:bg-gray-500 hover:text-white',
  },
};

const sizeClasses = {
  small: 'px-2 py-1 text-sm',
  medium: 'px-4 py-2 text-base',
  large: 'px-6 py-3 text-lg',
};

export const SelectBox = ({
  options,
  defaultValue,
  variant = 'outlined',
  color = 'primary',
  size = 'medium',
  className = '',
  onChange,
  disabled = false,
  direction = 'down',
}: SelectBoxProps) => {
  const [selected, setSelected] = useState<Option | undefined>(defaultValue);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const listboxRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  const updatePosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const itemHeight = 40;
      const dropdownHeight = options.length * itemHeight;

      setPosition({
        top: direction === 'up' ? rect.top - dropdownHeight : rect.bottom,
        left: rect.left,
        width: rect.width,
      });
    }
  };

  const variantClass = variantClasses[variant][color];
  const sizeClass = sizeClasses[size];

  return (
    <Listbox
      value={selected}
      onChange={(value: Option) => {
        setSelected(value);
        onChange?.(value.value);
      }}
      disabled={disabled || options.length === 0}
    >
      <div className="relative">
        <Listbox.Button
          ref={buttonRef}
          onClick={updatePosition}
          className={clsx(
            `rounded-lg w-full font-semibold ${variantClass} ${sizeClass} flex justify-between items-center`,
            className,
            disabled && 'opacity-50 cursor-not-allowed',
            options.length === 0 && 'opacity-50 cursor-not-allowed',
          )}
        >
          {selected ? (
            selected.label || selected.value
          ) : (
            <i className="text-control-plane-300 hover:text-gray-300">
              {options.length > 0 ? 'Select value...' : 'No options available'}
            </i>
          )}
          {direction === 'up' ? (
            <CaretUp className="ml-2" />
          ) : (
            <CaretDown className="ml-2" />
          )}
        </Listbox.Button>

        <Transition
          as={'div'}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {createPortal(
            <Listbox.Options
              ref={listboxRef}
              className={clsx(
                `absolute z-[9999] bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 mt-[4px] ml-[1px]`,
                `overflow-auto focus:outline-none sm:text-sm`,
              )}
              style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
                width: `${position.width}px`,
                position: 'fixed',
              }}
            >
              <div>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    value={option}
                    className={({ active }) =>
                      clsx(
                        'cursor-pointer select-none relative py-2 pl-3 pr-9',
                        active ? 'text-white bg-primary' : 'text-gray-900',
                      )
                    }
                  >
                    {option.label || option.value}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>,
            document.body,
          )}
        </Transition>
      </div>
    </Listbox>
  );
};
