import {
  ArrowSquareOut,
  Code,
  Copy,
  Eye,
  EyeClosed,
  Pencil,
} from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { get, patch, post } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Modal } from 'src/components/Modal';
import { Option, SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import {
  AccountConnection,
  AccountConnectionPayload,
} from 'src/interfaces/accountConnection.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { obfuscateToken } from 'src/utils/token';

const defaultProviders = [
  { value: 'account_connection_twilio', label: 'Twilio' },
  { value: 'account_connection_openai', label: 'OpenAI' },
];

const newAccountConnection: AccountConnectionPayload = {
  credentials: {
    twilio_account_sid: '',
    twilio_auth_token: '',
  },
};

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'type', label: 'Provider', width: '15%' },
  { key: 'info', label: 'Provider Info', width: '40%', disableSorting: true },
  { key: 'actions', label: 'Action', width: '15%', disableSorting: true },
];

export const ListAccountConnections = () => {
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [providers, setProviders] = useState<Option[]>(defaultProviders);
  const [providersKeyObfuscated, setProvidersKeyObfuscated] = useState<{
    [key: string]: boolean;
  }>({
    account_connection_twilio: true,
    account_connection_openai: true,
  });
  const [rows, setRows] = useState<AccountConnection[]>([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] =
    useState<AccountConnectionPayload>(newAccountConnection);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/account-connections?${query.toString()}`, {
      envId,
    });

    const items = data.items;

    const providersToBeObfuscated: { [key: string]: boolean } = {};

    for (const item of items) {
      providersToBeObfuscated[item.id] = true;
    }

    setProvidersKeyObfuscated(providersToBeObfuscated);

    setRows(items);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, sortConfig]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const resetForm = () => {
    setForm(newAccountConnection);
  };

  const openModalNewAccountConnection = () => {
    setModalOpen(true);
    setSelectedId('');
    resetForm();
  };

  const openModalEditAccountConnection = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
    const account = rows.find((item) => item.id === id);

    if (account) {
      setForm({
        credentials: {
          twilio_account_sid: account.credentials.twilio_account_sid,
          twilio_auth_token: account.credentials.twilio_auth_token,
          openai_api_key: account.credentials.openai_api_key,
        },
        type: account.type,
      });
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedId('');
    closeSidebar();
    resetForm();
  };

  const submitModal = async () => {
    setLoading(true);

    if (selectedId) {
      await patch(`/account-connections/${selectedId}`, form, {
        envId,
      });
      notification.success('Account updated successfully');
    } else {
      await post('/account-connections', form, {
        envId,
      });
      notification.success('Account created successfully');
    }

    fetchData(currentPage, size);

    setLoading(false);

    closeModal();
  };

  const changeProvider = (value: string) => {
    if (value === 'account_connection_twilio') {
      setForm({
        ...form,
        credentials: {
          twilio_account_sid: '',
          twilio_auth_token: '',
        },
        type: value,
      });
    } else {
      setForm({
        ...form,
        credentials: {
          openai_api_key: '',
        },
        type: value,
      });
    }
  };

  const hasTwilio = rows.some(
    (item) => item.type === 'account_connection_twilio',
  );

  const hasOpenAI = rows.some(
    (item) => item.type === 'account_connection_openai',
  );

  const hasBoth = hasTwilio && hasOpenAI;

  useEffect(() => {
    let newProviders: Option[] = [];

    if (!hasTwilio && !hasOpenAI) {
      newProviders = [
        { value: 'account_connection_twilio', label: 'Twilio' },
        { value: 'account_connection_openai', label: 'OpenAI' },
      ];
    } else if (hasTwilio && !hasOpenAI) {
      newProviders = [{ value: 'account_connection_openai', label: 'OpenAI' }];
    } else if (!hasTwilio && hasOpenAI) {
      newProviders = [{ value: 'account_connection_twilio', label: 'Twilio' }];
    }

    setProviders(newProviders);
  }, [hasTwilio, hasOpenAI]);

  const toggleProvidersKeyObfuscated = (key: string) => {
    setProvidersKeyObfuscated({
      ...providersKeyObfuscated,
      [key]: !providersKeyObfuscated[key],
    });
  };

  const endpoint = selectedId
    ? `account_connections/update?id=${selectedId}`
    : 'account_connections/create';

  const handleProviderEquivalentCode = () => {
    openSidebar(
      <EquivalentCode payload={form} endpoint={endpoint} method="POST" />,
      'Equivalent Code',
    );
  };

  const isValidForm = (form: AccountConnectionPayload) => {
    if (
      form.type === 'account_connection_twilio' &&
      (!form.credentials.twilio_account_sid ||
        !form.credentials.twilio_auth_token)
    )
      return true;

    if (
      form.type === 'account_connection_openai' &&
      !form.credentials.openai_api_key
    )
      return true;

    return false;
  };

  const disabledSubmit = () => {
    if (loading) return true;

    if (!form.type) return true;

    return isValidForm(form);
  };

  useEffect(() => {
    if (isOpen && !loading) {
      handleProviderEquivalentCode();
    }
  }, [form, isOpen]);

  return (
    <div className="flex-1">
      <Heading
        title="Providers"
        subtitle="Manage your account connections and providers."
      >
        <div className="flex mt-6">
          {!hasBoth && (
            <Button
              className="w-40 mr-2"
              onClick={openModalNewAccountConnection}
            >
              Create Account
            </Button>
          )}

          <Button
            className="w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/account_connections"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <Table
          headers={headers}
          rows={
            rows.map((item: AccountConnection) => ({
              ...item,
              id: <CopyableField value={item.id} notification={notification} />,
              type: defaultProviders.find((type) => type.value === item.type)
                ?.label,
              info: (
                <div>
                  {item.type === 'account_connection_twilio' && (
                    <div>
                      <p>
                        <span className="font-bold">Twilio Account SID:</span>{' '}
                        {item.credentials.twilio_account_sid}
                      </p>
                      <p>
                        <span className="font-bold">Twilio Auth Token:</span>{' '}
                        <span
                          className="cursor-pointer"
                          onClick={() => toggleProvidersKeyObfuscated(item.id)}
                        >
                          {providersKeyObfuscated[item.id]
                            ? obfuscateToken(item.credentials.twilio_auth_token)
                            : item.credentials.twilio_auth_token}{' '}
                          {providersKeyObfuscated[item.id] ? (
                            <Eye className="inline-block w-5 h-5" />
                          ) : (
                            <EyeClosed className="inline-block w-5 h-5" />
                          )}
                        </span>
                        <Tooltip content="Copy to clipboard" position="top">
                          <Copy
                            className="cursor-pointer inline-block w-5 h-5 ml-2"
                            onClick={() =>
                              copyToClipboard(
                                item.credentials.twilio_auth_token,
                                notification,
                              )
                            }
                          />
                        </Tooltip>
                      </p>
                    </div>
                  )}

                  {item.type === 'account_connection_openai' && (
                    <div>
                      <p>
                        <span className="font-bold">OpenAI API Key:</span>{' '}
                        <span
                          className="cursor-pointer"
                          onClick={() => toggleProvidersKeyObfuscated(item.id)}
                        >
                          {providersKeyObfuscated[item.id]
                            ? obfuscateToken(item.credentials.openai_api_key)
                            : item.credentials.openai_api_key}{' '}
                          {providersKeyObfuscated[item.id] ? (
                            <Eye className="inline-block w-5 h-5" />
                          ) : (
                            <EyeClosed className="inline-block w-5 h-5" />
                          )}
                        </span>
                        <Tooltip content="Copy to clipboard" position="top">
                          <Copy
                            className="cursor-pointer inline-block w-5 h-5 ml-2"
                            onClick={() =>
                              copyToClipboard(
                                item.credentials.openai_api_key,
                                notification,
                              )
                            }
                          />
                        </Tooltip>
                      </p>
                    </div>
                  )}
                </div>
              ),
              actions: (
                <>
                  <Button
                    className="ml-2"
                    onClick={() =>
                      openModalEditAccountConnection(item.id as string)
                    }
                  >
                    <Pencil className="cursor-pointer inline-block w-5 h-5" />
                  </Button>
                </>
              ),
            })) as never[]
          }
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={setSize}
          defaultSize={size}
          disablePagination
        />
      </ContentWrapper>

      <Modal
        title={`${selectedId ? 'Edit' : 'New'} Account Connection`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button
            color="default"
            onClick={submitModal}
            disabled={disabledSubmit()}
          >
            {selectedId ? 'Save Account' : 'Create Account'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              isOpen ? closeSidebar() : handleProviderEquivalentCode()
            }
            className="flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="flex justify-end italic text-sm mt-4">
          * Required fields
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {!selectedId && (
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="telephony_provider"
                >
                  Provider
                </label>

                <SelectBox
                  options={providers}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onChange={(value) => changeProvider(value)}
                  className="min-w-[140px]"
                  defaultValue={providers.find(
                    (item) => item.value === form.type,
                  )}
                  disabled={loading}
                  key={form.type}
                />
              </div>
            </div>
          )}

          <div>
            {form.type == 'account_connection_twilio' && (
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="twilio-account-sid"
                >
                  Twilio Account SID
                </label>

                <input
                  type="text"
                  id="twilio-account-sid"
                  placeholder="Enter Twilio Account SID"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        twilio_account_sid: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.twilio_account_sid}
                  disabled={loading}
                />
              </div>
            )}

            {form.type == 'account_connection_twilio' && (
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="twilio-auth-token"
                >
                  Twilio Auth Token
                </label>

                <input
                  type="password"
                  id="twilio-auth-token"
                  placeholder="Enter Twilio Auth Token"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        twilio_auth_token: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.twilio_auth_token}
                  disabled={loading}
                />
              </div>
            )}

            {form.type == 'account_connection_openai' && (
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="openai-api-key"
                >
                  OpenAI API Key
                </label>

                <input
                  type="password"
                  id="openai-api-key"
                  placeholder="Enter OpenAI API Key"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        openai_api_key: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.openai_api_key}
                  disabled={loading}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
