import { useLocalStorageState } from 'ahooks';
import { updateProfile, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Heading } from 'src/components/Heading';
import { useFirebaseAuth } from 'src/contexts/AuthContext/FirebaseAuthContext';
import { useNotification } from 'src/contexts/NotificationContext';

export const Profile = () => {
  const notification = useNotification();
  const auth = useFirebaseAuth();
  const currentUser = auth?.user as User | null;

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState<string | null>(null);

  const [, setFireBaseDisplayName] = useLocalStorageState<string | null>(
    '@control-plane/fireBaseDisplayName',
    {
      defaultValue: '',
      listenStorageChange: true,
    },
  );

  useEffect(() => {
    if (currentUser) {
      const displayName = currentUser.displayName || '';
      setFirstName(displayName.split(' ')[0] || '');
      setLastName(displayName.split(' ')[1] || '');
    }
  }, [currentUser]);

  const handleUpdateProfile = async () => {
    if (!currentUser) return;

    setLoading(true);
    setError(null);

    try {
      await updateProfile(currentUser, {
        displayName: `${firstName} ${lastName}`,
      });

      setFireBaseDisplayName(`${firstName} ${lastName}`);

      notification.success('Profile updated successfully');
    } catch (err) {
      notification.error('Failed to update profile');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex-1">
      <Heading title="Profile" subtitle="Update your profile information" />

      <ContentWrapper>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            {error && <div className="text-red-500 mb-4">{error}</div>}

            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 required-field"
                htmlFor="first-name"
              >
                First Name
              </label>

              <input
                type="text"
                id="first-name"
                placeholder="Enter first name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={loading}
              />
            </div>

            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 required-field"
                htmlFor="last-name"
              >
                Last Name
              </label>

              <input
                type="text"
                id="last-name"
                placeholder="Enter last name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                disabled={loading}
              />
            </div>

            <div className="flex justify-end italic text-sm mb-4">
              * Required fields
            </div>

            <Button
              className={`w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300`}
              onClick={handleUpdateProfile}
              disabled={loading || !firstName || !lastName}
            >
              {loading ? 'Saving...' : 'Update Profile'}
            </Button>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
