import { differenceInHours, format } from 'date-fns';
import TimeAgo from 'javascript-time-ago';

export const friendlyDateFormatter = (startDate: Date | null) => {
  const timeAgo = new TimeAgo('en-US');

  if (!startDate) {
    return '-';
  }

  const isLessThanOneHour = differenceInHours(new Date(), startDate) < 1;

  if (isLessThanOneHour) {
    return timeAgo.format(startDate);
  } else {
    return format(startDate, 'MM/dd/yyyy HH:mm:ss');
  }
};
