import { format } from 'date-fns';

export const CallStatus: { [key: string]: string } = {
  'All Statuses': 'All Statuses',
  not_started: 'Not started',
  in_progress: 'In progress',
  error: 'Error',
  ended: 'Ended',
};

export const CallStatusKeys = Object.fromEntries(
  Object.entries(CallStatus).map(([key, value]) => [value, key]),
);

export const periodsOptions = [
  { value: 'Last 24 Hours' },
  { value: '7 Days' },
  { value: '1 Month' },
  { value: 'All Time' },
];

export const eventsOptions = [
  { value: 'All Events' },
  { value: 'Error' },
  { value: 'Call' },
];

export const statusesOptions = [
  { value: 'All Statuses' },
  { value: 'Not started' },
  { value: 'In progress' },
  { value: 'Error' },
  { value: 'Ended' },
];

export const filter24Hours = {
  start_time: {
    comparison: 'gt',
    value: format(new Date().getTime() - 86400000, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
  },
  end_time: null,
};

export const filter7Days = {
  start_time: {
    comparison: 'gt',
    value: format(new Date().getTime() - 604800000, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
  },
  end_time: null,
};

export const filter1Month = {
  start_time: {
    comparison: 'gt',
    value: format(
      new Date().getTime() - 2592000000,
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
    ),
  },
  end_time: null,
};
