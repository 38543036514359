import {
  ArrowSquareOut,
  Code,
  Copy,
  MagnifyingGlass,
  Pencil,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { get, patch, post } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Modal } from 'src/components/Modal';
import { SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import {
  Action,
  ActionPayload,
  ActionType,
  PhraseTriggerConditionType,
  ProcessingMode,
  TriggerType,
} from 'src/interfaces/action.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { processPhoneNumber } from 'src/utils/number';
import { isValidHttpUrl } from 'src/utils/url';

const actions = [
  { label: 'End Conversation', value: ActionType.EndConversation },
  { label: 'DTMF', value: ActionType.DTMF },
  { label: 'Transfer Call', value: ActionType.TransferCall },
  { label: 'Add To Conference', value: ActionType.AddToConference },
  { label: 'Set Hold', value: ActionType.SetHold },
  { label: 'External', value: ActionType.External },
];

const triggerTypes = [
  { label: 'Function Call', value: TriggerType.FunctionCall },
  { label: 'Phrase Based', value: TriggerType.PhraseBased },
];

const yesNoOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const newAction: ActionPayload = {
  type: ActionType.EndConversation,
  config: {},
  action_trigger: {
    type: TriggerType.FunctionCall,
    config: {},
  },
};

const headers = [
  { key: 'id', label: 'ID', width: '30%' },
  { key: 'type', label: 'Action Type', width: '30%' },
  { key: 'phone_number', label: 'Phone Number', width: '30%' },
  { key: 'actions', label: 'Action', width: '20%', disableSorting: true },
];

export const ListActions = () => {
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [rows, setRows] = useState<Action[]>([]);
  const [originalRows, setOriginalRows] = useState<Action[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] = useState<ActionPayload>(newAction);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });
  const [inputsWithError, setInputsWithError] = useState<string[]>([]);

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);
    closeSidebar();

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/actions?${query.toString()}`, {
      envId,
    });

    const items = data.items;

    setRows(items);
    setOriginalRows(items);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, sortConfig]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const resetForm = () => {
    setForm(newAction);
  };

  const openModalNewAction = () => {
    setModalOpen(true);
    setSelectedId('');
    resetForm();
  };

  const openModalEditAction = (action: Action) => {
    setModalOpen(true);
    setSelectedId(action.id);

    if (action) {
      setForm({
        ...action,
        ...(action.config.input_schema && {
          config: {
            ...action.config,
            input_schema: JSON.stringify(action.config.input_schema),
          },
        }),
        action_trigger: action.action_trigger,
      });
    } else {
      notification.error('Action not found');
    }
  };

  const closeModal = () => {
    closeSidebar();
    setModalOpen(false);
    setSelectedId('');
    resetForm();
  };

  const submitModal = async () => {
    setLoading(true);

    // Deep clone the form to avoid mutating the original object
    const cloneForm = JSON.parse(JSON.stringify(form)) as ActionPayload;

    try {
      if (cloneForm.config.input_schema) {
        try {
          cloneForm.config.input_schema = JSON.parse(
            cloneForm.config.input_schema,
          );
        } catch {
          notification.error('Invalid JSON in input schema');
          setLoading(false);
          return;
        }
      }

      if (selectedId) {
        await patch(`/actions/${selectedId}`, cloneForm, {
          envId,
        });
        notification.success('Action updated successfully');
      } else {
        await post('/actions', cloneForm, {
          envId,
        });
        notification.success('Action created successfully');
      }

      resetForm();
      fetchData(currentPage, size);
      closeModal();
    } catch {
      notification.error('Failed to save action');
    } finally {
      setLoading(false);
    }
  };

  const submitFilter = () => {
    const filteredRows = originalRows.filter((row: Action) =>
      row.type?.toLowerCase()?.includes(filter.toLowerCase()),
    );

    setRows(filteredRows);
  };

  const handleEndpointAction = () => {
    if (selectedId) {
      return `actions/update?id=${selectedId}`;
    } else {
      return 'actions/create';
    }
  };

  const handleAgentEquivalentCode = () => {
    openSidebar(
      <EquivalentCode
        payload={form}
        endpoint={handleEndpointAction()}
        method="POST"
      />,
      'Equivalent Code',
    );
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValidHttpUrl(e.target.value)) {
      setInputsWithError([...inputsWithError, 'url']);
      setForm({
        ...form,
        config: {
          ...form.config,
          url: '',
        },
      });
    } else {
      setInputsWithError(inputsWithError.filter((input) => input !== 'url'));
    }
  };

  const isFieldEmpty = (field: string | undefined) => !field || field === '';

  const isValidForm = (form: ActionPayload) => {
    const { action_trigger, config, type } = form;

    if (
      action_trigger.type === TriggerType.PhraseBased &&
      (!action_trigger.config ||
        !action_trigger.config.phrase_triggers ||
        isFieldEmpty(action_trigger.config.phrase_triggers[0]?.phrase))
    ) {
      return true;
    }

    if (needsPhoneNumber && isFieldEmpty(config.phone_number)) {
      return true;
    }

    if (type === ActionType.External) {
      const requiredFields = [
        config.name,
        config.description,
        config.url,
        config.input_schema,
      ];

      if (
        requiredFields.some(isFieldEmpty) ||
        config.speak_on_send === undefined ||
        config.speak_on_receive === undefined ||
        !isValidHttpUrl(String(config.url))
      ) {
        return true;
      }
    }

    return false;
  };

  const disabledSubmit = () => {
    if (loading) return true;

    return isValidForm(form);
  };

  useEffect(() => {
    submitFilter();
  }, [debouncedFilter]);

  useEffect(() => {
    if (isOpen && !loading) {
      handleAgentEquivalentCode();
    }
  }, [form, isOpen]);

  const needsPhoneNumber =
    form.type === ActionType.TransferCall ||
    form.type === ActionType.AddToConference;

  const canChangeTrigger =
    form.type !== ActionType.DTMF && form.type !== ActionType.External;
  useEffect(() => {
    resetForm();
    const resetedForm = { ...form, type: form.type };

    if (form.type === ActionType.External && selectedId === '') {
      resetedForm.config = {
        name: '',
        description: '',
        url: '',
        speak_on_send: false,
        speak_on_receive: false,
        input_schema: '',
        processing_mode: 'muted',
        signature_secret: '',
      };
    }

    if (selectedId === '') {
      resetedForm.action_trigger = {
        type: TriggerType.FunctionCall,
        config: {},
      };
    }

    setForm(resetedForm);
  }, [form.type]);

  return (
    <div className="flex-1">
      <Heading
        title="Actions"
        subtitle="Actions give you control in the middle of a conversation, allowing dynamic behavior."
      >
        <div className="flex mt-6">
          <Button className="w-40" onClick={openModalNewAction}>
            Create Action
          </Button>

          <Button
            className="ml-4 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/actions"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            required
            placeholder="Search actions"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={submitFilter}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={
            rows.map((item: Action) => ({
              ...item,
              id: <CopyableField value={item.id} notification={notification} />,
              phone_number: item.config.phone_number
                ? processPhoneNumber(item.config.phone_number)
                : '',
              actions: (
                <>
                  <Tooltip content="Copy ID to clipboard" position="top">
                    <Button
                      variant="outlined"
                      onClick={() =>
                        copyToClipboard(
                          item.id,
                          notification,
                          'ID Copied to clipboard',
                        )
                      }
                    >
                      <Copy className="cursor-pointer inline-block w-5 h-5" />
                    </Button>
                  </Tooltip>

                  <Button
                    className="ml-2"
                    onClick={() => openModalEditAction(item)}
                  >
                    <Pencil className="cursor-pointer inline-block w-5 h-5" />
                  </Button>
                </>
              ),
            })) as never[]
          }
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={setSize}
          defaultSize={size}
        />
      </ContentWrapper>

      <Modal
        title={`${selectedId ? 'Edit' : 'New'} Action`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button
            color="default"
            onClick={submitModal}
            disabled={disabledSubmit()}
          >
            {selectedId ? 'Save Action' : 'Create Action'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              isOpen ? closeSidebar() : handleAgentEquivalentCode()
            }
            className="flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="flex justify-end italic text-sm mt-4">
          * Required fields
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 required-field"
              htmlFor="action-type"
            >
              Action Type
            </label>

            <SelectBox
              key={form.type}
              options={actions}
              defaultValue={actions.find(
                (action) => action.value === form.type,
              )}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) =>
                setForm({ ...form, type: value as ActionType })
              }
              className="min-w-[140px]"
              disabled={loading}
            />
          </div>

          {needsPhoneNumber && (
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 required-field"
                htmlFor="phone-number"
              >
                Phone Number
              </label>

              <input
                type="text"
                id="receiver-number"
                placeholder="Enter receiver number"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                value={form.config.phone_number}
                onChange={(e) =>
                  setForm({
                    ...form,
                    config: {
                      ...form.config,
                      phone_number: e.target.value as string,
                    },
                  })
                }
                disabled={loading}
              />
            </div>
          )}

          {canChangeTrigger && (
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 required-field"
                htmlFor="action-trigger"
              >
                Action Trigger
              </label>

              <SelectBox
                key={form.action_trigger.type}
                options={triggerTypes}
                defaultValue={triggerTypes.find(
                  (trigger) => trigger.value === form.action_trigger.type,
                )}
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) =>
                  setForm({
                    ...form,
                    action_trigger: { type: value as TriggerType },
                  })
                }
                className="min-w-[140px]"
                disabled={loading}
              />
            </div>
          )}

          {form.type === ActionType.AddToConference && (
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="place-primary-on-hold"
              >
                Place Primary on Hold
              </label>

              <SelectBox
                key={`place_primary_on_hold_${form.config.place_primary_on_hold}`}
                options={yesNoOptions}
                defaultValue={yesNoOptions.find(
                  (option) =>
                    option.value === String(form.config.place_primary_on_hold),
                )}
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) =>
                  setForm({
                    ...form,
                    config: {
                      ...form.config,
                      place_primary_on_hold: value === 'true',
                    },
                  })
                }
                className="min-w-[140px]"
                disabled={loading}
              />
            </div>
          )}

          {form.action_trigger.type === TriggerType.PhraseBased && (
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 required-field"
                htmlFor="phrase-trigger"
              >
                Phrase Trigger
              </label>

              <input
                type="text"
                id="phrase-trigger"
                placeholder="Enter phrase trigger"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                value={form.action_trigger.config?.phrase_triggers?.[0]?.phrase}
                onChange={(e) =>
                  setForm({
                    ...form,
                    action_trigger: {
                      ...form.action_trigger,
                      config: {
                        ...form.config,
                        phrase_triggers: [
                          {
                            phrase: e.target.value as string,
                            conditions: [
                              PhraseTriggerConditionType.PhraseConditionTypeContains,
                            ],
                          },
                        ],
                      },
                    },
                  })
                }
                disabled={loading}
              />
            </div>
          )}

          {form.type === ActionType.External && (
            <>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="name"
                >
                  Name
                </label>

                <input
                  type="text"
                  id="name"
                  placeholder="Enter name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  value={form.config.name}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      config: {
                        ...form.config,
                        name: e.target.value as string,
                      },
                    })
                  }
                  disabled={loading}
                />
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="description"
                >
                  Description
                </label>

                <input
                  type="text"
                  id="description"
                  placeholder="Enter description"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  value={form.config.description}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      config: {
                        ...form.config,
                        description: e.target.value as string,
                      },
                    })
                  }
                  disabled={loading}
                />
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="url"
                >
                  URL
                </label>

                <input
                  type="text"
                  id="url"
                  placeholder="Enter url"
                  className={clsx(
                    'w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400',
                    inputsWithError.includes('url') && 'border-red-500',
                  )}
                  value={form.config.url}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      config: { ...form.config, url: e.target.value as string },
                    })
                  }
                  onBlur={handleUrlChange}
                  disabled={loading}
                />

                {inputsWithError.includes('url') && (
                  <span className="text-red-500 text-xs font-bold">
                    Please enter a valid URL
                  </span>
                )}
              </div>

              <div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2 required-field"
                    htmlFor="speak_on_send"
                  >
                    Speak on Send
                  </label>

                  <SelectBox
                    key={`speak_on_send_${form.config.speak_on_send}`}
                    options={yesNoOptions}
                    defaultValue={yesNoOptions.find(
                      (option) =>
                        option.value === String(form.config.speak_on_send),
                    )}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onChange={(value) =>
                      setForm({
                        ...form,
                        config: {
                          ...form.config,
                          speak_on_send: value === 'true',
                        },
                      })
                    }
                    className="min-w-[140px]"
                    disabled={loading}
                  />
                </div>

                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2 required-field"
                    htmlFor="speak_on_receive"
                  >
                    Speak on Receive
                  </label>

                  <SelectBox
                    key={`speak_on_receive_${form.config.speak_on_receive}`}
                    options={yesNoOptions}
                    defaultValue={yesNoOptions.find(
                      (option) =>
                        option.value === String(form.config.speak_on_receive),
                    )}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onChange={(value) =>
                      setForm({
                        ...form,
                        config: {
                          ...form.config,
                          speak_on_receive: value === 'true',
                        },
                      })
                    }
                    className="min-w-[140px]"
                    disabled={loading}
                  />
                </div>
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="input_schema"
                >
                  Input Schema
                </label>

                <textarea
                  id="input_schema"
                  placeholder="Enter input schema"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  value={form.config.input_schema}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      config: {
                        ...form.config,
                        input_schema: e.target.value,
                      },
                    })
                  }
                  disabled={loading}
                  rows={4}
                />
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="processing_mode"
                >
                  Processing Mode
                </label>

                <SelectBox
                  key={form.config.processing_mode}
                  options={[{ label: 'Muted', value: 'muted' }]}
                  defaultValue={{ label: 'Muted', value: 'muted' }}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onChange={(value) =>
                    setForm({
                      ...form,
                      config: {
                        ...form.config,
                        processing_mode: value as ProcessingMode,
                      },
                    })
                  }
                  className="min-w-[140px]"
                  disabled={loading}
                />
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="signature_secret"
                >
                  Signature Secret
                </label>

                <input
                  type="text"
                  id="signature_secret"
                  placeholder="Enter signature secret"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  value={form.config.signature_secret}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      config: {
                        ...form.config,
                        signature_secret: e.target.value as string,
                      },
                    })
                  }
                  disabled={loading}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
