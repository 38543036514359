export enum ActionType {
  EndConversation = 'action_end_conversation',
  DTMF = 'action_dtmf',
  TransferCall = 'action_transfer_call',
  AddToConference = 'action_add_to_conference',
  SetHold = 'action_set_hold',
  External = 'action_external',
}

export enum TriggerType {
  FunctionCall = 'action_trigger_function_call',
  PhraseBased = 'action_trigger_phrase_based',
}

export enum PhraseTriggerConditionType {
  PhraseConditionTypeContains = 'phrase_condition_type_contains',
}

export type PhraseTriggerType = {
  phrase: string;
  conditions: PhraseTriggerConditionType[];
};

export type ActionTrigger = {
  type: TriggerType;
  config: {
    phrase_triggers: PhraseTriggerType[];
  };
};

export interface Action {
  id: string;
  type: ActionType;
  config: {
    phone_number: string;
    place_primary_on_hold: boolean;
    name: string;
    description: string;
    url: string;
    input_schema: string;
    speak_on_send: boolean;
    speak_on_receive: boolean;
    processing_mode: ProcessingMode;
    signature_secret: string;
  };
  action_trigger: ActionTrigger;
}

export type ProcessingMode = 'muted';

export interface ActionPayload {
  type: ActionType;
  config: {
    phone_number?: string;
    place_primary_on_hold?: boolean;
    name?: string;
    description?: string;
    url?: string;
    input_schema?: string;
    speak_on_send?: boolean;
    speak_on_receive?: boolean;
    processing_mode?: ProcessingMode;
    signature_secret?: string;
  };
  action_trigger: {
    type: TriggerType;
    config?: {
      phrase_triggers?: PhraseTriggerType[];
    };
  };
}
