import { useState } from 'react';
import { Button } from 'src/components/Button';
import { CheckCircle, ArrowLeft, ArrowRight } from '@phosphor-icons/react';
import { Heading } from './Heading';
import { ContentWrapper } from './ContentWrapper';

interface Step {
  title: string;
  component: JSX.Element;
}

type StepContent = Step[];

interface StepManagerProps {
  title: string;
  stepsContent: StepContent;
  finishAction?: () => void;
  loading?: boolean;
}

export const StepManager = ({
  title,
  stepsContent,
  finishAction,
  loading = false,
}: StepManagerProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  const isLastStep = currentStep === stepsContent.length - 1;
  const isFirstStep = currentStep === 0;

  const nextStep = () => {
    if (!isLastStep) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const previousStep = () => {
    if (!isFirstStep) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <div className="flex-1">
      <Heading title={title} />

      <ContentWrapper>
        <div className="w-full">
          <h2 className="text-xl font-semibold text-control-plane-400 mb-4">
            {stepsContent[currentStep].title}
          </h2>
          <div className="text-gray-600 mb-6">
            {stepsContent[currentStep].component}
          </div>

          <div className="flex justify-between">
            <Button
              onClick={previousStep}
              disabled={isFirstStep || loading}
              className={`${
                isFirstStep ? 'hidden bg-gray-200' : 'bg-control-plane-400'
              } text-white px-4 py-2 rounded-md focus:outline-none`}
            >
              <ArrowLeft size={20} className="inline-block mr-2" />
              Previous
            </Button>

            {isFirstStep && <div></div>}

            {isLastStep ? (
              <Button
                onClick={finishAction}
                className="bg-green-500 text-white px-4 py-2 rounded-md focus:outline-none"
                disabled={loading || !finishAction}
              >
                <CheckCircle size={20} className="inline-block mr-2" />
                Save
              </Button>
            ) : (
              <Button
                onClick={nextStep}
                className="bg-control-plane-400 text-white px-4 py-2 rounded-md focus:outline-none"
                disabled={loading}
              >
                Next
                <ArrowRight size={20} className="inline-block ml-2" />
              </Button>
            )}
          </div>
        </div>

        <div className="flex justify-center items-center mt-6">
          {stepsContent.map((_, index) => (
            <div
              key={index}
              className={`w-8 h-8 border-2 ${
                index <= currentStep
                  ? 'border-control-plane-400 bg-control-plane-400 text-white'
                  : 'border-gray-300 bg-white'
              } rounded-full flex items-center justify-center mx-1`}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </ContentWrapper>
    </div>
  );
};
