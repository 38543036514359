import { ArrowSquareOut, MagnifyingGlass } from '@phosphor-icons/react';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Button } from 'src/components/Button';
import { CallDetails } from 'src/components/CallDetails';
import { CallRecordingButton } from 'src/components/CallRecordingButton';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { Heading } from 'src/components/Heading';
import { SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { Agent } from 'src/interfaces/agent.interface';
import { Call, ComparisonFilter } from 'src/interfaces/call.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import {
  CallStatus,
  CallStatusKeys,
  eventsOptions,
  filter1Month,
  filter24Hours,
  filter7Days,
  periodsOptions,
  statusesOptions,
} from 'src/stubs/calls.stub';
import { friendlyDateFormatter } from 'src/utils/date';
import { getFirstAndLastFourUUID, processPhoneNumber } from 'src/utils/number';
import { get } from '../api/requests';

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'agent', label: 'Agent', width: '8rem' },
  { key: 'error_message', label: 'Event', width: '10%' },
  { key: 'recording_available', label: 'Recording', width: '15%' },
  { key: 'start_time', label: 'Created At', width: '15%' },
  { key: 'end_time', label: 'Duration', width: '15%' },
  { key: 'from_number', label: 'From', width: '15%' },
  { key: 'to_number', label: 'To', width: '15%' },
  { key: 'status', label: 'Status', width: '10%' },
  { key: 'actions', label: 'Actions', width: '10%', disableSorting: true },
];

export const Calls = () => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const { openSidebar } = useRightHandSidebar();
  const envId = environment?.envId;

  const [agents, setAgents] = useState([]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [filters, setFilters] = useState({
    agent: 'All Agents',
    eventType: 'All Events',
    status: 'All Statuses',
    timePeriod: 'All Time',
  });

  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const agentsOptions = [
    { value: 'All Agents' },
    ...agents.map((agent: { id: string }) => ({
      value: agent.id,
      label: getFirstAndLastFourUUID(agent.id),
    })),
  ];

  const updateFilters = (newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, ...newFilters };
      setCurrentPage(1);
      return updatedFilters;
    });
  };

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const filtersCopy: Partial<
      typeof filters & {
        do_not_call_result: boolean;
        start_time: ComparisonFilter;
        end_time: ComparisonFilter;
      }
    > = {
      ...filters,
      ...(filters.agent !== 'All Agents'
        ? { agent: filters.agent }
        : { agent: undefined }),
      ...(filters.eventType !== 'All Events'
        ? {
            eventType: filters.eventType,
          }
        : { eventType: undefined }),
      ...(filters.status !== 'All Statuses'
        ? { status: filters.status }
        : { status: undefined }),
      timePeriod: undefined,
      ...(filters.eventType === 'Error' ? { do_not_call_result: true } : {}),
    };

    if (filters.timePeriod) {
      const period = prepareFilterPeriod(filters.timePeriod);

      if (period.start_time) {
        filtersCopy.start_time = period.start_time;
      }

      if (period.end_time) {
        filtersCopy.end_time = period.end_time;
      }
    }

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      filters: JSON.stringify(filtersCopy),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/calls?${query.toString()}`, {
      envId,
    });

    const calls = data.items.map((call: Call) => {
      const startDate = call.start_time
        ? new Date(Number(call.start_time) * 1000)
        : null;
      const endDate = call.end_time
        ? new Date(Number(call.end_time) * 1000)
        : null;

      return {
        ...call,
        id: <CopyableField value={call.id} notification={notification} />,
        agent: <CopyableField value={call.agent} notification={notification} />,
        error_message: call.error_message
          ? 'Error: ' + call.error_message
          : 'Call',
        recording_available: call.recording_available ? (
          <CallRecordingButton
            callId={call.id}
            envId={envId}
            recordingAvailable={call.recording_available}
          />
        ) : (
          '-'
        ),
        start_time: friendlyDateFormatter(startDate),
        end_time:
          startDate && endDate
            ? formatDuration(
                intervalToDuration({
                  start: startDate,
                  end: endDate,
                }),
                { delimiter: ', ' },
              )
            : '-',
        from_number: processPhoneNumber(call.from_number),
        to_number: processPhoneNumber(call.to_number),
        status: CallStatus[call.status],
        actions: (
          <Tooltip content="Show call details" position="top">
            <Button variant="outlined" onClick={() => handleCallDetails(call)}>
              <MagnifyingGlass className="cursor-pointer inline-block w-5 h-5" />
            </Button>
          </Tooltip>
        ),
      };
    });

    setRows(calls as never[]);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, filters, sortConfig, size]);

  const handlePageChange = async (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const filterByEventType = (eventType: 'All Events' | 'Error' | 'Call') => {
    updateFilters({ eventType });
  };

  const filterByTimePeriod = (
    timePeriod: 'Last 24 Hours' | '7 Days' | '1 Month' | 'All Time',
  ) => {
    updateFilters({ timePeriod });
  };

  const filterByAgent = (agent: string) => {
    updateFilters({ agent });
  };

  const filterByStatus = (status: string) => {
    const statusKey = Object.keys(CallStatus).find(
      (key) => CallStatus[key] === status,
    );
    updateFilters({ status: statusKey ?? 'All Statuses' });
  };

  const fetchAgents = async () => {
    const data = await get(`/agents`, {
      envId,
    });

    setAgents((prevAgents) => [
      ...(prevAgents as never[]),
      ...(data.items as never[]),
    ]);
  };

  const searchAgents = async (name: string) => {
    const queryObject: QueryObject = {
      ...(name.length !== 0 && { filters: JSON.stringify({ name }) }),
    };
    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/agents?${query.toString()}`, {
      envId,
    });

    return data.items.map((agent: Agent) => ({
      value: agent.id,
      label: agent.name,
    }));
  };

  const handleCallDetails = (call: Call) => {
    openSidebar(<CallDetails call={call} />, 'Call Details');
  };

  const prepareFilterPeriod = (
    period: string,
  ): {
    start_time: ComparisonFilter | null;
    end_time: ComparisonFilter | null;
  } => {
    switch (period) {
      case 'Last 24 Hours':
        return filter24Hours;
      case '7 Days':
        return filter7Days;
      case '1 Month':
        return filter1Month;
      case 'All Time':
      default:
        return {
          start_time: null,
          end_time: null,
        };
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <div className="flex-1">
      <Heading
        title="Calls"
        subtitle="See the calls made using your agents here"
      >
        <div className="w-fit flex flex-1 items-center justify-end align-bottom">
          <div>
            <label
              className="block text-control-plane-400 text-sm font-bold mb-2 mt-6"
              htmlFor="period"
            >
              Period
            </label>

            <SelectBox
              key={filters.timePeriod}
              options={periodsOptions}
              defaultValue={periodsOptions.find(
                (period) => period.value === filters.timePeriod,
              )}
              variant="contained"
              color="primary"
              size="medium"
              onChange={(value) =>
                filterByTimePeriod(
                  value as 'Last 24 Hours' | '7 Days' | '1 Month' | 'All Time',
                )
              }
              className="w-40"
            />
          </div>

          <div className="mt-[3px]">
            <Button
              className="ml-4 w-42 border-none flex items-center justify-center mt-12"
              variant="outlined"
              color="default"
              href="https://docs.fluents.ai/api-reference/calls"
              target="_blank"
            >
              View help doc
              <ArrowSquareOut className="ml-2" size={18} />
            </Button>
          </div>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-control-plane-400 mb-4">
            Filter by:
          </h2>

          <div className="flex flex-col gap-4 lg:grid lg:grid-cols-6 lg:gap-4">
            <div>
              <label
                className="block text-control-plane-400 text-sm font-bold mb-2"
                htmlFor="events"
              >
                Events
              </label>

              <SelectBox
                key={filters.eventType}
                options={eventsOptions}
                defaultValue={eventsOptions.find(
                  (event) => event.value === filters.eventType,
                )}
                variant="contained"
                color="primary"
                size="medium"
                onChange={(value) =>
                  filterByEventType(value as 'All Events' | 'Error' | 'Call')
                }
                className="col-span-2"
              />
            </div>

            <div>
              <label
                className="block text-control-plane-400 text-sm font-bold mb-2"
                htmlFor="agents"
              >
                Agents
              </label>

              <SelectBox
                key={filters.agent}
                options={agentsOptions}
                defaultValue={agentsOptions.find(
                  (agent) => agent.value === filters.agent,
                )}
                variant="contained"
                color="primary"
                size="medium"
                onChange={(value) => filterByAgent(value)}
                className="col-span-2 hidden"
              />

              <AutoCompleteSelectBox
                defaultOptions={agentsOptions}
                defaultValue={agentsOptions.find(
                  (agent) => agent.value === filters.agent,
                )}
                fetchOptions={searchAgents}
                onChange={(value) => filterByAgent(value)}
                variant="contained"
                color="primary"
                size="medium"
                disabled={loading}
              />
            </div>

            <div>
              <label
                className="block text-control-plane-400 text-sm font-bold mb-2"
                htmlFor="status"
              >
                Statuses
              </label>

              <SelectBox
                key={filters.status}
                options={statusesOptions}
                defaultValue={statusesOptions.find(
                  (status) => CallStatusKeys[status.value] === filters.status,
                )}
                variant="contained"
                color="primary"
                size="medium"
                className="col-span-2"
                onChange={(value) => filterByStatus(value)}
              />
            </div>
          </div>
        </div>

        <Table
          headers={headers}
          rows={rows}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>
    </div>
  );
};
