export const isValidHttpUrl = (urlToCheck: string) => {
  let url;

  try {
    url = new URL(urlToCheck);
  } catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};
