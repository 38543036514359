import { useState } from 'react';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Heading } from 'src/components/Heading';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { EnvironmentPayload } from 'src/interfaces/environment.interface';
import { ApiKeys } from './components/ApiKeys';
import { Users } from './components/Users';
import { useNotification } from 'src/contexts/NotificationContext';
import { post } from 'src/api/requests';
import { Loading } from 'src/components/Loading';
import { Modal } from 'src/components/Modal';

export const Settings = () => {
  const notification = useNotification();
  const { environment, fetchEnvironments, environmentList } = useEnvironment();
  const envId = environment?.envId;

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [newEnvironment, setNewEnvironment] = useState<EnvironmentPayload>(
    {} as EnvironmentPayload,
  );

  const handleCreateEnvironment = () => {
    setModalOpen(true);
  };

  const createEnvironment = async () => {
    setLoading(true);

    try {
      await post(`/environments`, newEnvironment, { envId });
      await fetchEnvironments();

      notification.success('Environment created successfully');
      setModalOpen(false);
    } catch {
      notification.error('Failed to create environment');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex-1">
      <Heading title="Settings" subtitle="Manage your organization settings">
        <Button
          className="mt-6 w-60"
          disabled={loading}
          onClick={handleCreateEnvironment}
        >
          Create Environment
        </Button>
      </Heading>

      {loading && <Loading />}

      {!loading && (
        <ContentWrapper>
          <ApiKeys
            envId={String(envId)}
            fetchEnvironments={fetchEnvironments}
            environmentList={environmentList}
          />
          <Users envId={String(envId)} />
        </ContentWrapper>
      )}

      <Modal
        title={`Create Environment`}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        actionButton={
          <Button
            color="default"
            onClick={createEnvironment}
            disabled={loading || !newEnvironment || !newEnvironment.name}
          >
            Create Environment
          </Button>
        }
      >
        <div className="flex justify-end italic text-sm">* Required fields</div>

        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2 required-field">
              Name
            </label>
            <input
              type="text"
              value={newEnvironment.name}
              onChange={(e) =>
                setNewEnvironment({ ...newEnvironment, name: e.target.value })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
