import { ArrowFatLinesDown, ArrowFatLinesUp } from '@phosphor-icons/react';
import clsx from 'clsx';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { Heading } from 'src/components/Heading';
import { SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Call } from 'src/interfaces/call.interface';
import { Usage } from 'src/interfaces/usage.interface';
import { CallStatus } from 'src/stubs/calls.stub';
import { friendlyDateFormatter } from 'src/utils/date';
import { processPhoneNumber } from 'src/utils/number';
import { get } from '../api/requests';

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'agentFriendlyUUID', label: 'Agent', width: '8rem' },
  { key: 'event', label: 'Event', width: '10%' },
  { key: 'recording', label: 'Recording', width: '15%' },
  { key: 'start_time', label: 'Created At', width: '15%' },
  { key: 'duration', label: 'Duration', width: '15%' },
  { key: 'from', label: 'From', width: '15%' },
  { key: 'to', label: 'To', width: '15%' },
  { key: 'status', label: 'Status', width: '10%' },
];

const plans = {
  plan_free: 'Free',
  plan_developer: 'Developer',
  plan_enterprise: 'Enterprise',
  plan_unlimited: 'Unlimited',
};

export const Home = () => {
  const notification = useNotification();
  const statusData = [
    {
      title: 'Avg Turn Latency',
      value: '123ms',
      tooltipText: 'This is the average latency',
      labelIcon: <ArrowFatLinesUp className="text-green-500" />,
    },
    {
      title: 'API Error Rate',
      value: '0.1%',
      tooltipText: 'This is the API error rate',
      labelIcon: <ArrowFatLinesDown className="text-red-500" />,
    },
    {
      title: 'Input tokens per minute',
      value: '100',
      tooltipText: 'This is the input tokens per minute',
    },
    {
      title: 'Output tokens per minute',
      value: '100',
      tooltipText: 'This is the output tokens per minute',
    },
    {
      title: 'Est. Cost per minute',
      value: '$0.01',
      tooltipText: 'This is the estimated cost per minute',
    },
    {
      title: 'Avg. Concurrency',
      value: '100',
      tooltipText: 'This is the average concurrency',
    },
    {
      title: 'Avg. Success Rate',
      value: '100%',
      tooltipText: 'This is the average success rate',
    },
  ];

  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const navigate = useNavigate();

  const periods = ['1h', '24h', '7d', 'All-time'];
  const agents = ['agent1', 'agent2', 'agent3'];

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });
  const [usage, setUsage] = useState<Usage | undefined>(undefined);

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const query = new URLSearchParams({
      page: String(page),
      size: String(perPage),
    });

    const data = await get(`/calls?${query.toString()}`, {
      envId,
    });

    const calls = data.items.map((call: Call) => {
      const startDate = call.start_time
        ? new Date(Number(call.start_time) * 1000)
        : null;
      const endDate = call.end_time
        ? new Date(Number(call.end_time) * 1000)
        : null;

      return {
        ...call,
        id: <CopyableField value={call.id} notification={notification} />,
        agentFriendlyUUID: (
          <CopyableField value={call.agent} notification={notification} />
        ),
        event: call.error_message ? 'Error: ' + call.error_message : 'Call',
        recording: call.recording_available ? 'Available' : '-',
        start_time: friendlyDateFormatter(startDate),
        duration:
          startDate && endDate
            ? formatDuration(
                intervalToDuration({
                  start: startDate,
                  end: endDate,
                }),
                { delimiter: ', ' },
              )
            : '-',
        from: processPhoneNumber(call.from_number),
        to: processPhoneNumber(call.to_number),
        status: CallStatus[call.status],
      };
    });

    setRows(calls as never[]);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, 5);
  }, [currentPage]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    const sortedRows = [...rows];

    if (direction === 'asc') {
      sortedRows.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    } else {
      sortedRows.sort((a, b) => (a[key] < b[key] ? 1 : -1));
    }

    setRows(sortedRows);
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const fetchUsage = async () => {
      const usageData = await get('/usage', {
        envId,
      });

      setUsage(usageData);
    };

    fetchUsage();
  }, [envId]);

  return (
    <div className="flex-1">
      <Heading title="Dashboard" subtitle="Welcome to the dashboard">
        {usage && (
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="p-4 border border-gray-200 rounded shadow hover:shadow-lg transition">
              <div className="flex items-center justify-between">
                <span className="text-xl font-semibold">Plan Type</span>
              </div>
              <p className="text-lg">
                {plans[usage.plan_type as keyof typeof plans]}
              </p>
            </div>

            <div className="p-4 border border-gray-200 rounded shadow hover:shadow-lg transition">
              <div className="flex items-center justify-between">
                <span className="text-xl font-semibold">Monthly Usage</span>
              </div>
              <p className="text-lg">
                {usage.monthly_usage_minutes} / {1000} minutes
              </p>
              <div className="h-2 bg-gray-200 rounded mt-2">
                <div
                  className={clsx('h-full', {
                    'bg-primary': usage.monthly_usage_minutes < 800,
                    'bg-yellow-400':
                      usage.monthly_usage_minutes >= 800 &&
                      usage.monthly_usage_minutes < 1000,
                    'bg-red-400': usage.monthly_usage_minutes >= 1000,
                  })}
                  style={{
                    width: `${(usage.monthly_usage_minutes / 1000) * 100}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </Heading>

      <ContentWrapper>
        <div className="flex justify-between items-center mb-6 hidden">
          <h2 className="text-xl font-semibold text-control-plane-400 mb-4">
            Statistics
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <SelectBox
              options={agents.map((agent) => ({ value: agent }))}
              defaultValue={{ value: 'agent1' }}
              variant="contained"
              color="primary"
              size="medium"
              onChange={(value) => console.log(value)}
              className="min-w-[140px]"
            />

            <SelectBox
              options={periods.map((period) => ({ value: period }))}
              defaultValue={{ value: '24h' }}
              variant="contained"
              color="primary"
              size="medium"
              onChange={(value) => console.log(value)}
              className="min-w-[140px]"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 hidden">
          {statusData.map((status, index) => (
            <div
              key={index}
              className="p-4 border border-gray-200 rounded shadow hover:shadow-lg transition"
            >
              <div className="flex items-center justify-between">
                <span className="text-xl font-semibold">{status.title}</span>
                {status.labelIcon && <span>{status.labelIcon}</span>}
              </div>
              <p className="text-lg">{status.value}</p>
              <p className="text-gray-500">{status.tooltipText}</p>
            </div>
          ))}
        </div>

        <div className="mt-0">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-xl font-semibold text-control-plane-400 mb-4">
                Last Calls
              </h2>

              <span className="text-primary-subtitle">
                See the last 5 calls made using your agents here
              </span>
            </div>

            <Button onClick={() => navigate('/calls')}>Calls Page</Button>
          </div>

          <Table
            headers={headers}
            rows={rows}
            totalItems={totalItems}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onSort={handleSort}
            loading={loading}
            defaultSize={5}
            disablePagination
          />
        </div>
      </ContentWrapper>
    </div>
  );
};
