import { Code, Handshake, Headset, Money } from '@phosphor-icons/react';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'src/api/requests';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Button } from 'src/components/Button';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { MultiSelectBox } from 'src/components/MultiSelectBox';
import { SelectBox } from 'src/components/SelectBox';
import { VOICE_NAMES } from 'src/constants';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { Action } from 'src/interfaces/action.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { Webhook } from 'src/interfaces/webhook.interface';
import { limitString } from 'src/utils/limitString';
import { getFirstAndLastFourUUID } from 'src/utils/number';
import { AgentStepProps, Language } from '../../../interfaces/agent.interface';
import { AGENT_TEMPLATES } from '../constants';

const TEMPLATE_ICONS: { [key: string]: ReactNode } = {
  customer_support: <Headset />,
  debt_relief: <Money />,
  sales: <Handshake />,
};

const languages = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'de', label: 'German' },
  { value: 'hi', label: 'Hindi' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'fr', label: 'French' },
  { value: 'nl', label: 'Dutch' },
  { value: 'id', label: 'Indonesian' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
];

const modelNames = [
  { value: 'gpt-4', label: 'GPT-4' },
  { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
  { value: 'gpt-4o', label: 'GPT-4 Omni' },
  { value: 'gpt-4o-mini', label: 'GPT-4 Omni Mini' },
  { value: 'gpt-3.5', label: 'GPT-3.5' },
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
];

export const AgentSingleStep = ({
  agent,
  setAgent,
  loading = false,
  prompts,
  voices,
  webhooks,
  actions,
}: AgentStepProps & {
  prompts: Prompt[];
  setPrompts: (prompts: Prompt[]) => void;
  voices: Voice[];
  webhooks: Webhook[];
  actions: Action[];
}) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const currentVoice = voices.find((voice) => voice.id === agent.voice) || {
    id: undefined,
    voice_name: '',
  };
  const currentPrompt = prompts.find((prompt) => prompt.id === agent.prompt);
  const [promptContent, setPromptContent] = useState('');

  const promptList = prompts.map((prompt) => ({
    value: prompt.id,
    label: limitString(prompt.content, 50),
  }));

  const webhookList = webhooks?.map((webhook) => ({
    value: webhook.id,
    label: webhook.url,
  }));

  const actionList = actions?.map((action) => ({
    value: action.id,
    label: `${action.type} (${getFirstAndLastFourUUID(action.id)})`,
  }));

  const handlePromptEdit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromptContent(event.target.value);
  };

  const endpoint = agent.id ? `agents/update?id=${agent.id}` : 'agents/create';

  const handleAgentEquivalentCode = () => {
    openSidebar(
      <EquivalentCode payload={agent} endpoint={endpoint} method="POST" />,
      'Equivalent Code',
    );
  };

  const searchPrompts = async (content: string) => {
    const queryObject: QueryObject = {
      ...(content.length !== 0 && { filters: JSON.stringify({ content }) }),
    };
    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/prompts?${query.toString()}`, {
      envId,
    });

    return data.items.map((prompt: Prompt) => ({
      value: prompt.id,
      label: limitString(prompt.content, 50),
    }));
  };

  const searchVoices = async (voice_name: string) => {
    const queryObject: QueryObject = {
      ...(voice_name.length !== 0 && {
        filters: JSON.stringify({ voice_name }),
      }),
    };
    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/voices?${query.toString()}`, {
      envId,
    });

    return data.items.map((voice: Voice) => ({
      value: voice.id,
      label: voice.voice_name,
    }));
  };

  const searchWebhooks = async (url: string) => {
    const queryObject: QueryObject = {
      ...(url.length !== 0 && { filters: JSON.stringify({ url }) }),
    };
    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/webhooks?${query.toString()}`, {
      envId,
    });

    return data.items.map((webhook: Webhook) => ({
      value: webhook.id,
      label: webhook.url,
    }));
  };

  useEffect(() => {
    async function populatePromptContent() {
      if (typeof agent?.prompt === 'string') {
        const newPromptContent = prompts.find(
          (prompt) => prompt.id === agent.prompt,
        )?.content;

        setPromptContent(newPromptContent || '');
      }

      if (typeof agent?.prompt === 'object') {
        setPromptContent(agent.prompt.content);
      }
    }

    populatePromptContent();
  }, [agent?.prompt, prompts]);

  useEffect(() => {
    if (promptContent !== currentPrompt?.content) {
      setAgent({ ...agent, prompt: { content: promptContent } });
    }
  }, [promptContent]);

  useEffect(() => {
    if (isOpen && !loading) {
      handleAgentEquivalentCode();
    }
  }, [agent, isOpen]);

  return (
    <div>
      <div className="flex justify-end mb-1">
        <Button
          onClick={() =>
            isOpen ? closeSidebar() : handleAgentEquivalentCode()
          }
          className="flex items-center justify-center"
        >
          <Code className="mr-2" size={20} />
          Show equivalent code
        </Button>
      </div>

      <div className="flex justify-end italic text-sm mt-4">
        * Required fields
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <div className="mb-3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="agent-name"
            >
              Agent Name
            </label>

            <input
              type="text"
              id="agent-name"
              placeholder="Enter agent name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              value={agent.name}
              onChange={(e) => setAgent({ ...agent, name: e.target.value })}
              disabled={loading}
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="initialMessage"
          >
            Initial Message
          </label>

          <input
            type="text"
            id="initialMessage"
            placeholder="Hi {name}, this is Fluents AI. How can I help you today?"
            value={agent.initial_message}
            onChange={(e) =>
              setAgent({ ...agent, initial_message: e.target.value })
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
          />
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 required-field"
            htmlFor="voice"
          >
            Voice
          </label>

          {voices.length === 0 && (
            <span className="text-gray-700 text-sm font-bold">
              No voices available, pick one on the voices page. Click{' '}
              <Link to="/voices">here</Link>
            </span>
          )}

          {voices.length > 0 && (
            <AutoCompleteSelectBox
              key={currentVoice.id}
              defaultOptions={voices.map((voice) => ({
                value: String(voice.id),
                label: VOICE_NAMES[voice.type].find(
                  (elm) =>
                    elm.value ===
                    (voice.voice_name || voice.voice_id || voice.speaker),
                )?.label,
              }))}
              defaultValue={
                currentVoice.id
                  ? {
                      value: currentVoice.id,
                      label: currentVoice.voice_name,
                    }
                  : undefined
              }
              fetchOptions={searchVoices}
              onChange={(value) => {
                setAgent({ ...agent, voice: value });
              }}
              className="min-w-[140px]"
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
            />
          )}
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="prompt"
          >
            Prompt Library
          </label>

          {promptList.length === 0 && (
            <span className="text-gray-700 text-sm font-bold">
              No prompts available, try creating one on the prompts page. Click{' '}
              <Link to="/prompts">here</Link>
            </span>
          )}

          {promptList.length > 0 && (
            <AutoCompleteSelectBox
              key={String(agent.prompt)}
              defaultOptions={promptList}
              defaultValue={promptList.find(
                (prompt) => agent.prompt === prompt.value,
              )}
              fetchOptions={searchPrompts}
              onChange={(value) => {
                setAgent({ ...agent, prompt: value });
              }}
              className="min-w-[140px]"
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
            />
          )}
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="prompt"
          >
            Webhooks
          </label>

          {webhookList.length === 0 && (
            <span className="text-gray-700 text-sm font-bold">
              No webhooks available, try creating one on the webhooks page.
              Click <Link to="/webhooks">here</Link>
            </span>
          )}

          {webhookList.length > 0 && (
            <AutoCompleteSelectBox
              key={String(agent.webhook)}
              defaultOptions={webhookList}
              defaultValue={webhookList.find(
                (webhook) => webhook.value === String(agent.webhook),
              )}
              fetchOptions={searchWebhooks}
              onChange={(value) => {
                setAgent({ ...agent, webhook: value });
              }}
              className="min-w-[140px]"
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
            />
          )}
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="action"
          >
            Actions
          </label>

          {actionList.length === 0 && (
            <span className="text-gray-700 text-sm font-bold">
              No actions available, try creating one on the actions page. Click{' '}
              <Link to="/actions">here</Link>
            </span>
          )}

          {actionList.length > 0 && (
            <MultiSelectBox
              key={agent.actions?.join('-')}
              defaultValue={
                actionList.filter((action) =>
                  agent.actions?.includes(action.value),
                ) || []
              }
              options={actionList}
              variant="contained"
              color="primary"
              size="medium"
              className="min-w-[140px]"
              onChange={(value) => setAgent({ ...agent, actions: value })}
              disabled={loading}
            />
          )}
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="language"
          >
            Language
          </label>

          <SelectBox
            options={languages}
            key={agent.language}
            defaultValue={{
              value: agent.language,
              label: languages.find((lang) => lang.value === agent.language)
                ?.label,
            }}
            variant="contained"
            color="primary"
            size="medium"
            onChange={(value) =>
              setAgent({ ...agent, language: value as Language })
            }
            className="min-w-[140px]"
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="openai_model_name_override"
          >
            Model Name
          </label>

          <SelectBox
            options={modelNames}
            key={agent.openai_model_name_override}
            defaultValue={
              agent.openai_model_name_override
                ? {
                    value: String(agent.openai_model_name_override),
                    label: modelNames.find(
                      (model) =>
                        model.value === agent.openai_model_name_override,
                    )?.label,
                  }
                : undefined
            }
            variant="contained"
            color="primary"
            size="medium"
            onChange={(value) =>
              setAgent({
                ...agent,
                openai_model_name_override: value as string,
              })
            }
            className="min-w-[140px]"
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="conversationSpeed"
          >
            Conversation Speed <i className="text-gray-400">(seconds)</i>
          </label>

          <input
            type="range"
            id="conversationSpeed"
            min="0.2"
            max="5"
            step="0.2"
            value={agent.conversation_speed}
            onChange={(e) =>
              setAgent({
                ...agent,
                conversation_speed: parseFloat(e.target.value),
              })
            }
            className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb"
          />

          <span className="text-gray-700 text-sm font-bold">
            {agent.conversation_speed}
          </span>
        </div>
        <div className="mb-3 col-span-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Prompt Templates
          </label>
          <div className="flex space-x-6">
            {Object.keys(AGENT_TEMPLATES).map((key) => {
              const label = key
                .split('_')
                .map(
                  (labelPart) =>
                    `${labelPart[0].toUpperCase()}${labelPart.substring(1)}`,
                )
                .join(' ');
              return (
                <Button
                  key={key}
                  color="secondary"
                  className="flex flex-col items-center w-48"
                  onClick={() => setPromptContent(AGENT_TEMPLATES[key])}
                >
                  {TEMPLATE_ICONS[key]}
                  <div className="mt-2">{label}</div>
                </Button>
              );
            })}
          </div>
        </div>
        <div className="mb-6 col-span-2">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 required-field"
            htmlFor="text"
          >
            Prompt
          </label>
          <textarea
            id="text"
            placeholder="Prompt text"
            value={promptContent}
            onChange={handlePromptEdit}
            className="w-full h-40 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400 md:h-80"
          />
        </div>
      </div>
    </div>
  );
};
