import { Pause, Play } from '@phosphor-icons/react';
import { useRef, useState } from 'react';
import { get } from 'src/api/requests';
import { Button } from './Button';
import clsx from 'clsx';

type CallRecordingButtonProps = {
  callId: string;
  envId: string | undefined;
  recordingAvailable: boolean;
};

export const CallRecordingButton = ({
  callId,
  envId,
  recordingAvailable,
}: CallRecordingButtonProps) => {
  const [loadingRecording, setLoadingRecording] = useState(false);
  const [audioState, setAudioState] = useState({
    playing: false,
    currentTime: 0,
    duration: 0,
  });

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const togglePlayback = async () => {
    if (!recordingAvailable) return;

    if (!audioRef.current) {
      setLoadingRecording(true);

      try {
        const recording = await get(`/calls/recording/${callId}`, { envId });
        const blob = new Blob([new Uint8Array(recording.data)], {
          type: 'audio/mpeg',
        });
        const audioUrl = URL.createObjectURL(blob);

        const audio = new Audio(audioUrl);
        audioRef.current = audio;

        audio.addEventListener('timeupdate', () => {
          setAudioState((prevState) => ({
            ...prevState,
            currentTime: Math.floor(audio.currentTime),
            duration: Math.floor(audio.duration),
          }));
        });

        audio.addEventListener('ended', () => {
          setAudioState({
            playing: false,
            currentTime: 0,
            duration: audio.duration || 0,
          });
          URL.revokeObjectURL(audioUrl);
          audioRef.current = null;
        });

        setLoadingRecording(false);
        audio.play();
        setAudioState((prevState) => ({
          ...prevState,
          playing: true,
        }));
      } catch (error) {
        console.error('Error fetching recording:', error);
        setLoadingRecording(false);
      }
    } else {
      const audio = audioRef.current;
      if (audioState.playing) {
        audio.pause();
        setAudioState((prevState) => ({
          ...prevState,
          playing: false,
        }));
      } else {
        audio.play();
        setAudioState((prevState) => ({
          ...prevState,
          playing: true,
        }));
      }
    }
  };

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!audioRef.current || audioState.duration === 0) return;

    const progressBar = e.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const newTime = (clickX / rect.width) * audioState.duration;

    audioRef.current.currentTime = newTime;
    setAudioState((prevState) => ({
      ...prevState,
      currentTime: newTime,
    }));
  };

  const progressPercentage =
    audioState.duration > 0
      ? (audioState.currentTime / audioState.duration) * 100
      : 0;

  return (
    <div className="relative">
      <Button
        disabled={loadingRecording}
        className={clsx(
          'relative flex items-center drop-shadow-md',
          'overflow-hidden',
          audioState.playing && 'w-full',
        )}
        onClick={!audioState.playing ? togglePlayback : undefined}
      >
        {audioState.playing ? <Pause onClick={togglePlayback} /> : <Play />}
        {audioState.playing && (
          <div className="cursor-pointer" onClick={togglePlayback}>
            {' '}
            ({audioState.currentTime}/{Math.ceil(audioState.duration)} sec)
          </div>
        )}

        {audioState.playing && (
          <div
            className="absolute left-0 bottom-0 h-1 bg-gray-300 w-full cursor-pointer"
            onClick={handleProgressBarClick}
          >
            <div
              className="h-full bg-white"
              style={{
                width: `${progressPercentage}%`,
                transition: 'width 0.1s linear',
              }}
            ></div>
          </div>
        )}
      </Button>
    </div>
  );
};
