import { ArrowBendRightDown, ArrowBendRightUp } from '@phosphor-icons/react';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { SelectBox } from './SelectBox';
import { Loading } from './Loading';
import { breakpoints } from 'src/hooks/useBreakpoint';
import { useLocation } from 'react-router-dom';

interface Header {
  key: string;
  label: string;
  width?: string;
  disableSorting?: boolean;
}

export interface Row {
  [key: string]: string | number | React.ReactElement;
}

interface TableProps {
  headers: Header[];
  rows: Row[];
  totalItems: number;
  currentPage: number;
  onPageChange: (page: number, perPage: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSort: (key: string | any, direction: string) => void;
  loading: boolean;
  defaultSize?: number;
  disablePagination?: boolean;
  setSize?: (size: number) => void;
}

const Table = ({
  headers,
  rows,
  totalItems,
  currentPage,
  onPageChange,
  onSort,
  loading,
  defaultSize = 10,
  disablePagination = false,
  setSize = () => {},
}: TableProps) => {
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({
    key: null,
    direction: null,
  });

  const [perPage, setPerPage] = useState<number>(defaultSize);

  const handleSort = (key: string | null) => {
    let direction = 'asc';

    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    if (key) {
      onSort(key, direction);
    }
  };

  const totalPages = Math.ceil(totalItems / perPage);

  const renderSortIcon = (key: string | null) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'asc' ? (
      <ArrowBendRightDown />
    ) : (
      <ArrowBendRightUp />
    );
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page, perPage);
    }
  };

  const handlePerPageChange = (value: string) => {
    setSize(Number(value));
    setPerPage(Number(value));
    onPageChange(1, Number(value));
  };

  const renderPagination = () => {
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    const pages = [];

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return (
      <div className="flex items-center space-x-2">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-200 text-gray-600 rounded disabled:opacity-50"
        >
          Previous
        </button>

        {pages.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-4 py-2 ${
              currentPage === page
                ? 'bg-control-plane-400 text-white'
                : 'bg-gray-200'
            } rounded`}
          >
            {page}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-200 text-gray-600 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    );
  };

  useEffect(() => {
    setIsMobile(window.innerWidth < breakpoints.tablet);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoints.tablet);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="w-full">
      {loading ? (
        <div className="text-center py-4">
          <Loading size={'8'} />
        </div>
      ) : (
        <>
          {!isMobile && (
            <table className="min-w-full bg-white border table-auto">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th
                      key={header.key}
                      className={clsx(
                        'px-4 py-2 border-b-2 text-left cursor-pointer text-control-plane-500',
                      )}
                      onClick={() =>
                        !header.disableSorting && handleSort(header.key)
                      }
                      style={{
                        width: header.width && `${header.width}`,
                        maxWidth: header.width && `${header.width}`,
                      }}
                    >
                      <div className="flex items-center">
                        {header.label}{' '}
                        {!header.disableSorting && renderSortIcon(header.key)}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex} className="hover:bg-gray-100">
                    {headers.map((header) => (
                      <td
                        key={header.key}
                        className={clsx('px-4 py-2 border-b')}
                        style={{
                          width: header.width && `${header.width}`,
                          maxWidth: header.width && `${header.width}`,
                        }}
                      >
                        {typeof row[header.key] === 'object'
                          ? (row[header.key] as JSX.Element)
                          : row[header.key]}
                      </td>
                    ))}
                  </tr>
                ))}

                {rows.length === 0 && (
                  <tr>
                    <td colSpan={headers.length} className="text-center py-4">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {isMobile && (
            <div className="flex flex-col">
              {rows.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  className="flex flex-col border border-gray-300 rounded mb-4"
                >
                  {headers.map((header) => (
                    <div
                      key={header.key}
                      className="flex justify-between px-2 py-2 border-b last:border-none"
                    >
                      <span className="font-semibold">{header.label}</span>
                      <span>{row[header.key]}</span>
                    </div>
                  ))}
                </div>
              ))}

              {rows.length === 0 && (
                <div className="text-center py-4">No data available</div>
              )}
            </div>
          )}

          {!disablePagination && (
            <div className="flex py-2 justify-end">{renderPagination()}</div>
          )}

          {!disablePagination && (
            <div className="flex py-2 justify-end align-center items-center">
              <label htmlFor="perPage" className="mr-2">
                Items per page:
              </label>

              <SelectBox
                options={[5, 10, 20, 30].map((value) => ({
                  value: String(value),
                }))}
                defaultValue={{ value: String(perPage) }}
                variant="contained"
                color="primary"
                size="medium"
                onChange={handlePerPageChange}
                className="w-10"
                direction="up"
                key={`${rows.length}-${perPage}`}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Table;
