import {
  ArrowSquareOut,
  Code,
  MagnifyingGlass,
  Pencil,
  Trash,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { get, patch, remove } from 'src/api/requests';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Modal } from 'src/components/Modal';
import { Option } from 'src/components/SelectBox';
import Table, { Row } from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { Agent } from 'src/interfaces/agent.interface';
import { Number } from 'src/interfaces/number.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { getFirstAndLastFourUUID, processPhoneNumber } from 'src/utils/number';
import { BuyNumber } from './components/BuyNumber';
import { LinkNumber } from './components/LinkNumber';
import { AccountConnection } from 'src/interfaces/accountConnection.interface';

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'number', label: 'Number', width: '20%' },
  { key: 'inbound_agent', label: 'Agent', width: '8rem' },
  { key: 'telephony_provider', label: 'Telephony Provider', width: '10%' },
  {
    key: 'telephony_account_connection',
    label: 'Account Connection',
    width: '8rem',
  },
  { key: 'actions', label: 'Action', width: '20%', disableSorting: true },
];

export const ListNumbers = () => {
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const notification = useNotification();

  const [rows, setRows] = useState<Number[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [modalBuyOpen, setModalBuyOpen] = useState(false);
  const [modalLinkOpen, setModalLinkOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const [agents, setAgents] = useState<Option[]>([]);
  const [accountConnections, setAccountConnections] = useState<Option[]>([]);

  const [editingNumber, setEditingNumber] = useState<Number | null>(null);
  const [cancelingNumber, setCancelingNumber] = useState<Number | null>(null);

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      ...(filter.length !== 0 && {
        filters: JSON.stringify({ number: filter }),
      }),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/numbers?${query.toString()}`, {
      envId,
    });

    const items = data.items.map((item: Number) => ({
      ...item,
      number: processPhoneNumber(item.number),
      telephony_account_connection: getFirstAndLastFourUUID(
        item.telephony_account_connection || '',
      ),
    }));

    setRows(items);

    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, sortConfig, debouncedFilter, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    handlePageChange(1, size);
  };

  const openEditModal = (number: Number) => {
    setEditingNumber(number);
    setModalEditOpen(true);
  };

  const closeEditModal = () => {
    setEditingNumber(null);
    closeSidebar();
    setModalEditOpen(false);
  };

  const cleanPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/[^0-9]/g, '');
  };

  const confirmEditAction = async () => {
    if (editingNumber) {
      setLoading(true);

      await patch(
        `/numbers/${editingNumber.id}?phone_number=${cleanPhoneNumber(editingNumber.number)}`,
        {
          inbound_agent: editingNumber.inbound_agent,
        },
        { envId },
      );

      setLoading(false);

      notification.success('Number updated successfully');
      setModalEditOpen(false);
      fetchData(currentPage, size);
      setEditingNumber(null);
    }
  };

  const openDeleteModal = (number: Number) => {
    setCancelingNumber(number);
    setModalDeleteOpen(true);
  };

  const confirmDeleteAction = async () => {
    if (cancelingNumber) {
      setLoading(true);

      const cleanedNumber = cleanPhoneNumber(cancelingNumber.number);

      await remove(`/numbers/cancel/${cleanedNumber}`, { envId });

      setLoading(false);

      notification.success('Number canceled successfully');
      setModalDeleteOpen(false);
      fetchData(currentPage, size);
      setCancelingNumber(null);
    }
  };

  const closeDeleteModal = () => {
    setModalDeleteOpen(false);
    setCancelingNumber(null);
  };

  const endpoint = editingNumber
    ? `numbers/update?phone_number=${cleanPhoneNumber(editingNumber.number)}`
    : ``;

  const handleProviderEquivalentCode = () => {
    openSidebar(
      <EquivalentCode
        payload={
          editingNumber
            ? {
                inbound_agent: editingNumber.inbound_agent,
              }
            : {}
        }
        endpoint={endpoint}
        method="POST"
      />,
      'Equivalent Code',
    );
  };

  const searchAgents = async (name: string) => {
    const queryObject: QueryObject = {
      ...(name.length !== 0 && { filters: JSON.stringify({ name }) }),
    };
    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/agents?${query.toString()}`, {
      envId,
    });

    return data.items.map((agent: Agent) => ({
      value: agent.id,
      label: agent.name,
    }));
  };

  const fetchAgents = async () => {
    const data = await get('/agents', {
      envId,
    });

    setAgents(
      data.items.map((agent: Agent) => ({
        value: agent.id || '',
        label: agent.name,
      })),
    );
  };

  const fetchAccountConnections = async () => {
    const data = await get('/account-connections', {
      envId,
    });

    setAccountConnections(
      data.items.map((accountConnection: AccountConnection) => ({
        value: accountConnection.id || '',
        label: getFirstAndLastFourUUID(accountConnection.id || ''),
      })),
    );
  };

  useEffect(() => {
    if (isOpen && !loading) {
      handleProviderEquivalentCode();
    }
  }, [editingNumber, isOpen]);

  const fetchRelatedData = async () => {
    await Promise.all([fetchAgents(), fetchAccountConnections()]);
  };

  useEffect(() => {
    fetchRelatedData();
  }, []);

  return (
    <div className="flex-1">
      <Heading
        title="Numbers"
        subtitle="Phone numbers available for your account"
      >
        <div className="flex mt-6">
          <Button className="mr-2" onClick={() => setModalBuyOpen(true)}>
            Buy
          </Button>

          <Button onClick={() => setModalLinkOpen(true)}>Import</Button>

          <Button
            className="ml-2 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/numbers"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            required
            placeholder="Search numbers"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={submitFilter}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={rows.map(
            (row) =>
              ({
                id: (
                  <CopyableField value={row.id} notification={notification} />
                ),
                number: (
                  <Tooltip
                    content={
                      row.active
                        ? 'Copy Number to clipboard'
                        : 'Number is inactive'
                    }
                    position="top"
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        copyToClipboard(
                          row.number,
                          notification,
                          'Number Copied to clipboard',
                        )
                      }
                      disabled={!row.active}
                    >
                      {row.number}
                    </Button>
                  </Tooltip>
                ),
                inbound_agent: (
                  <CopyableField
                    value={row.inbound_agent}
                    notification={notification}
                  />
                ),
                telephony_provider: row.telephony_provider,
                telephony_account_connection: row.telephony_account_connection,
                actions: (
                  <>
                    <Button className="ml-2" onClick={() => openEditModal(row)}>
                      <Pencil className="cursor-pointer inline-block w-5 h-5" />
                    </Button>

                    {row.active && (
                      <Button
                        className="ml-2"
                        onClick={() => openDeleteModal(row)}
                        color="error"
                      >
                        <Trash className="cursor-pointer inline-block w-5 h-5" />
                      </Button>
                    )}
                  </>
                ),
              }) as Row,
          )}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>

      <BuyNumber
        onClose={() => setModalBuyOpen(false)}
        refreshData={() => fetchData(currentPage, size)}
        modalOpen={modalBuyOpen}
        setModalOpen={setModalBuyOpen}
        agents={agents}
        accountConnections={accountConnections}
      />

      <LinkNumber
        onClose={() => setModalLinkOpen(false)}
        refreshData={() => fetchData(currentPage, size)}
        modalOpen={modalLinkOpen}
        setModalOpen={setModalLinkOpen}
        agents={agents}
        accountConnections={accountConnections}
      />

      {/* Edit Number Modal */}
      <Modal
        title="Edit Number"
        isOpen={modalEditOpen}
        onClose={() => closeEditModal()}
        actionButton={
          <Button
            color="default"
            onClick={() => confirmEditAction()}
            disabled={loading}
          >
            Save Number
          </Button>
        }
      >
        {editingNumber && (
          <>
            <div className="flex justify-end mb-1">
              <Button
                onClick={() =>
                  isOpen ? closeSidebar() : handleProviderEquivalentCode()
                }
                className="flex items-center justify-center"
              >
                <Code className="mr-2" size={20} />
                Show equivalent code
              </Button>
            </div>

            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Current Number
                </label>
                <input
                  type="text"
                  value={editingNumber.number}
                  disabled
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Select Agent
                </label>
                <AutoCompleteSelectBox
                  defaultOptions={agents}
                  defaultValue={agents.find(
                    (agent) => agent.value === editingNumber.inbound_agent,
                  )}
                  key={editingNumber.inbound_agent}
                  fetchOptions={searchAgents}
                  onChange={(value) =>
                    setEditingNumber({
                      ...editingNumber,
                      inbound_agent: value,
                    })
                  }
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={loading}
                />
              </div>
            </div>
          </>
        )}
      </Modal>

      {/* Cancel Number Modal */}
      <Modal
        title="Cancel Number"
        isOpen={modalDeleteOpen}
        onClose={closeDeleteModal}
        actionButton={
          <Button
            color="error"
            onClick={() => confirmDeleteAction()}
            disabled={loading}
          >
            Cancel Number
          </Button>
        }
      >
        <p className="text-sm text-gray-700">
          Are you sure you want to cancel this number? (
          {cancelingNumber?.number})
        </p>
      </Modal>
    </div>
  );
};
